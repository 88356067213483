<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    v-if="props.links"
    class="flex bg-white"
  >
    <div class="container flex justify-between transition-all">
      <div class="flex h-full items-stretch">
        <span
          v-for="(item, index) in props.links"
          :key="index"
          class="relative -my-px mr-16 flex cursor-pointer items-center justify-center text-base font-normal leading-4 opacity-90 last:mr-0"
        >
          <NuxtLink
            v-slot="{ isActive, href, isExternal }"
            :to="item.link"
            custom
            no-prefetch
          >
            <a
              :href="href"
              class="text-black transition-all ease-linear will-change-transform before:absolute before:bottom-0 before:left-1/2 before:h-1 before:w-0 before:-translate-x-1/2 before:bg-orange before:transition-all hover:before:w-full"
              :class="[
                { 'is-active': isActive },
                {
                  'py-5': props.compactMode,
                  'py-7': !props.compactMode,
                },
              ]"
              @click.prevent="emit('navigate', item, isExternal)"
            >{{ item.title }}</a>
          </NuxtLink>
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiNavLink } from '../../UiNav/UiNav.types'

const props = withDefaults(
  defineProps<{
    links: UiNavLink[]
    compactMode?: boolean
  }>(),
  {
    compactMode: false,
  },
)

const emit = defineEmits<{
  (e: 'navigate', link: UiNavLink, isExternal: boolean): void
}>()
</script>
